<template>
    <!-- page-wrapper Start-->
    <div class="page-wrapper">
        <div class="container-fluid p-0">
            <!-- login page start-->
            <div class="authentication-main">
                <div class="row">
                    <div class="col-md-12">
                        <div class="auth-innerright">
                            <div class="authentication-box">
                                <div class="text-center"><img alt="" src="../assets/images/logo-trans.png" style="width:100%"></div>
                                <div class="card mt-4">
                                    <div class="card-body">
                                        <div class="text-center">
                                            <h4>LOGIN</h4>
                                            <h6>Vul uw gebruikersnaam en wachtwoord in om in te loggen </h6>
                                        </div>
                                        <form class="theme-form">
                                            <div class="form-group">
                                                <label class="col-form-label pt-0">Gebruikersnaam/E-mail</label>
                                                <input v-model="username" class="form-control" required="" type="text" v-on:keyup.enter="onEnter">
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Wachtwoord</label>
                                                <input v-model="password" autocomplete="" class="form-control" required="" type="password" v-on:keyup.enter="onEnter">
                                            </div>
                                            <div class="checkbox p-0">
                                                <input id="checkbox1" type="checkbox">
                                                <label for="checkbox1">Onthoud mij</label>
                                            </div>
                                            <div class="form-group form-row mt-3 mb-0">
                                                <button @click="login" class="btn btn-primary btn-block" type="button">Inloggen</button>
                                            </div>
                                            <!--<div class="form-group form-row mt-3 mb-0">
                                                <button @click="login" class="btn btn-secondary btn-block" type="button">Login With
                                                    Auth0
                                                </button>
                                            </div>
                                            <div class="login-divider"></div>
                                            <div class="social mt-3">
                                                <div class="form-group btn-showcase d-flex">
                                                    <button @click="socialLoginFacebook" class="btn social-btn btn-fb d-inline-block"><i
                                                            class="fa fa-facebook"></i></button>
                                                    <button @click="socialLogin"  class="btn social-btn btn-twitter d-inline-block"><i
                                                            class="fa fa-google"></i></button>
                                                    <button @click="socialLoginTwitter" class="btn social-btn btn-google d-inline-block"><i
                                                            class="fa fa-twitter"></i></button>
                                                    <button @click="socialLoginGit" class="btn social-btn btn-github d-inline-block"><i
                                                            class="fa fa-github"></i></button>
                                                </div>
                                            </div>-->
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- login page end-->
        </div>
    </div>
    <!-- latest jquery-->
</template>

<script>
import store from '../store/index'
export default {
    name: 'login',
    data() {
        return {
            username: '',
            password: ''
        }
    },
    computed: {   
        error() {   
            return store.state.auth.error
        },
        //isAuthenticated() {
        //    return store.state.auth.isAuthenticated
        //}
    },
    watch: {
        error (newError, oldError) {
            if (newError !== null) {
                console.log(newError)
                this.password = ''
                this.$toasted.show(newError, {theme: 'bubble',   position: "bottom-center",   type: 'error', duration: 2000});
            }
        },
        isAuthenticated (oldState, newState) {
            console.log("login.vue => isAuthenticated", newState)
            if (newState === true) {
                this.$router.replace('/')
            }
        }
    },
    methods: {
        onEnter: function() {
            this.login();
        },
        /*signUp: function () {
            if (this.username == "" && this.password == '') {
                    this.username = "test@admin.com",
                    this.password = "test@123456"
            } else {
                /*firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
                    (result) => {
                        Userauth.localLogin(result)
                        this.$router.replace('/')
                    },
                    (err) => {
                        this.email = "test@admin.com",
                        this.password = "test@123456"
                        this.$toasted.show('Oops...' + err.message, {theme: 'bubble',   position: "bottom-right",   type: 'error', duration: 2000});
                    }
                );*
            }
        },*/
        login () {
            if (this.email == "" || this.password == "") {
                this.$toasted.show('Geen emailadres of wachtwoord ingevuld', {theme: 'bubble',   position: "bottom-right",   type: 'error', duration: 2000});
                return
            }

            store.dispatch('auth/LogIn', {username: this.username, password: this.password}).then(() => {
                this.$router.push('/dashboard')
            })
            //console.log(this)
            //Userauth.login()
        }
    }
}
</script>