<template>
    <div>
        <Breadcrumbs main="Dashboard" title="dashboard"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-8 xl-100">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="chart-widget-dashboard">
                                        <div class="media">
                                            <div class="media-body">
                                                <h5 class="mt-0 mb-0 f-w-600"><feather type="dollar-sign"/><span class="counter">5789</span></h5>
                                                <p>Total Visits</p>
                                            </div><feather type="tag" />
                                        </div>
                                        <div class="dashboard-chart-container">
                                            <div class="small-chart-gradient-1">
                                                <chartist
                                                        ratio="ct-major-second"
                                                        type="Line"
                                                        :data="chart.chartData_1"
                                                        :options="chart.chartOptions" 
                                                        :event-handlers="chart.eventHandlers" >
                                                </chartist>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="chart-widget-dashboard">
                                        <div class="media">
                                            <div class="media-body">
                                                <h5 class="mt-0 mb-0 f-w-600"><feather type="dollar-sign"/><span class="counter">4986</span></h5>
                                                <p>Total Sale</p>
                                            </div><feather type="shopping-cart" />
                                        </div>
                                        <div class="dashboard-chart-container">
                                            <div class="small-chart-gradient-1">
                                                <chartist
                                                        ratio="ct-major-second"
                                                        type="Line"
                                                        :data="chart.chartData_2"
                                                        :options="chart.chartOptions"
                                                        :event-handlers="chart.eventHandlers" >
                                                </chartist>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="chart-widget-dashboard">
                                        <div class="media">
                                            <div class="media-body">
                                                <h5 class="mt-0 mb-0 f-w-600"><feather type="dollar-sign"/><span class="counter">8568</span></h5>
                                                <p>Total Value</p>
                                            </div><feather type="sun" />
                                        </div>
                                        <div class="dashboard-chart-container">
                                            <div class="small-chart-gradient-1">
                                                <chartist
                                                        ratio="ct-major-second"
                                                        type="Line"
                                                        :data="chart.chartData_3"
                                                        :options="chart.chartOptions"
                                                        :event-handlers="chart.eventHandlers" >
                                                </chartist>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>
var primary = localStorage.getItem('primary_color') || '#4466f2';
var secondary = localStorage.getItem('secondary_color') || '#1ea6ec';
var Knob = require('knob')
var profit = Knob({
  value: 35,
  angleOffset: 90,
  className: "review",
  thickness: 0.2,
  width: 268,
  height: 268,
  fgColor: primary,
})
import * as Chartist from 'chartist';
 export default {        
        data() {
            return {
                chart: {
                    chartData_1: {
                        labels: ['01', '02', '03', '04', '05', '06', '07'],
                        series: [
                            [0, 2, 1.2, 4, 2, 3, 1.5, 0]
                        ],
                    },
                    chartData_2: {
                        labels: ['01', '02', '03', '04', '05', '06'],
                        series: [
                            [0, 2, 1.2, 4, 2, 3, 0]
                        ]
                    },
                    chartData_3: {
                        labels: ['01', '02', '03', '04', '05', '06', '07'],
                        series: [
                            [0, 2, 1.2, 4, 2, 3, 1.5, 2, 0]
                        ]
                    },
                    chartOptions: {
                        // lineSmooth: true,
                        lineSmooth: Chartist.Interpolation.simple({divisor: 2 }),
                        fullWidth: true,
                        showArea: !0,
                        showPoint: false,
                        chartPadding: {
                            right: 0,
                            left: 0,
                            bottom: 0,
                        },
                        axisY: {
                            low: 0,
                            showGrid: false,
                            showLabel: false,
                            offset: 0
                        },
                        axisX: {
                            showGrid: false,
                            showLabel: false,
                            offset: 0
                        },
                    },
                    eventHandlers : [{
                        event: 'created',
                        fn(data) {
                            var defs = data.svg.elem('defs');
                            defs.elem('linearGradient', {
                                id: 'gradient2',
                                x1: 1,
                                y1: 1,
                                x2: 0,
                                y2: 0
                            }).elem('stop', {
                                offset: 0,
                                'stop-color': primary
                            }).parent().elem('stop', {
                                offset: 1,
                                'stop-color': secondary
                            });
                        }
                    }]
                
                },
                smooth_chart: {
                    chartData: {
                        labels: ['2009', '2010', '2011', '2012'],
                        series: [
                            [0, 60, 20, 60],
                            [0, 70, 10, 80]
                        ]
                    },
                    chartOptions: {
                        height: '358px',
                        fullWidth: true,
                        offset: -5,
                        low: 0,
                        chartPadding: {
                            right: 0,
                            left:-15,
                            top: 0,
                            bottom:-15
                        }
                    },
                     eventHandlers : [{
                        event: 'created',
                        fn(data) {
                            var defs = data.svg.elem('defs');
                            defs.elem('linearGradient', {
                                id: 'gradient1',
                               x1: 0,
                               y1: 0,
                               x2: 1,
                               y2: 1
                            }).elem('stop', {
                                offset: 0,
                                'stop-color': primary
                            }).parent().elem('stop', {
                                offset: 1,
                                'stop-color': secondary
                            });
                        }
                    }]
                },
                line_chart: {
                    chartData: {
                        labels: ['01', '02', '03', '04', '05', '06', '07', '08'],
                        series: [
                            [0, 2, 1.2, 4, 2, 3, 1.5, 0],
                            [0, 1, 2.2, 1.5, 3, 1.5, 2.25, 0]
                        ]
                    },
                    chartOptions: {
                        low: 0,
                        chartPadding: {
                            right: 0,
                            left:-15,
                            top: 0,
                            bottom:-15
                        },
                        showArea: true,
                        fullWidth: true,
                        onlyInteger: true,
                        
                        axisY: {
                            low: 0,
                            scaleMinSpace: 50,
                        },
                        axisX: {
                            showGrid: false
                        }
                    },
                    eventHandlers : [{
                        event: 'created',
                        fn(data) {
                            var defs = data.svg.elem('defs');
                            defs.elem('linearGradient', {
                                id: 'gradient1',
                                x1: 0,
                                y1: 0,
                                x2: 1,
                                y2: 1
                            }).elem('stop', {
                                offset: 0,
                                'stop-color': primary
                            }).parent().elem('stop', {
                                offset: 1,
                                'stop-color': secondary
                            });
                        }
                    }]
                }
            }
        },
        mounted () {
        }

    }
</script>
<style>
div.review canvas {
  position: relative !important;
}
div.review input {
  z-index: 1;
  display: inline-block;
}
</style>
