<template>
    <div>
        <Breadcrumbs main="Uren" title="Weekstaten"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                  <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                        <h5 slot="header">Weekstaten</h5>
                        <b-card-text class="mb-0">
                          <div class="form-row">
                                <div class="col-md-3 mb-3">
                                    <label for="c_form_first_name">Medewerker</label>
                                    <b-form-select type="text" id="c_form_first_name" :disabled="loader" :options="this.medewerkers" v-model="selectedMedewerker"></b-form-select>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label for="c_form_last_name">Jaar</label>
                                    <b-form-select type="text" id="c_form_first_name" :disabled="loader" :options="this.years" v-model="selectedYear"></b-form-select>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label for="c_form_last_name">Week</label>
                                    <b-form-select type="text" id="c_form_first_name" :disabled="loader" :options="this.weeks" v-model="selectedWeek"></b-form-select>
                                </div>
                                <div class="col-md-3 mb-3">
                                  <label for="default-info">.</label>
                                  <b-button id="default-info" variant="info" :disabled="loader" v-on:click="loadData()">Vernieuw</b-button>
							                    <b-tooltip target="default-info" triggers="hover" placement="top">Herlaad de huidige selectie</b-tooltip>
                                </div>
                                <!--<div class="col-md-4 mb-3">
                                    <label for="c_form_username">Username</label>
                                    <b-form-input type="text" id="c_form_username" v-model="c_form.username" :state="c_form_result.username" placeholder="Username"></b-form-input>
                               </div>-->
                          </div>
                          <div class="table-responsive mb-0">
                            <b-table striped hover :busy="loader" :fields="fields" :items="days" :tbody-tr-class="rowClass">
                              <template #table-busy>
                                <div class="text-center text-primary my-2">
                                  <b-spinner class="align-middle"></b-spinner>
                                  <strong>Laden...</strong>
                                </div>
                              </template>

                              <template #cell(show_details)="row">
                                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                                  {{ row.detailsShowing ? 'Verberg' : 'Toon' }} details</b-button>
                              </template>

                              <template #row-details="row">
                                <b-table :fields="subFields" :items="row.item.Items">
                                  <template #cell(WorkOrder)="data">
                                    <strong>{{ data.value.Code}}</strong> - {{ data.value.CustomerName }}<br />
                                    {{ data.value.Description}}
                                  </template>
                                </b-table>
                              </template>
                            </b-table>
                          </div>
                          <!--<div class="table-responsive datatable-vue m-0 vue-smart">
                            <v-table :data="hours" class="table">
                              <thead slot="head">
                                  <th>Datum</th>
                                  <th>Omschrijving</th>
                                  <th>Tijd</th>
                              </thead>
                              <tbody slot="body" slot-scope="{displayData}">
                                  <tr v-for="row in displayData" :key="row.GC_ID">
                                    <td>{{ row.DATUM }}</td>
                                    <td>{{ row.OMSCHRIJVING }}</td>
                                    <td>{{ row.AMOUNT }}</td>
                                  </tr>
                              </tbody>
                            </v-table>
                            </div>-->
                        </b-card-text>
                    </b-card>
              </div>               
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>

// Returns the ISO week of the date.
Date.prototype.getWeek = function() {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}
Date.prototype.getString = function() {
  return ("00" + this.getDate()).substr(-2) + "-" + 
         ("00" + (this.getMonth() + 1)).substr(-2) + "-" +
         this.getFullYear();
}
Date.prototype.getWeekDay = function() {
  let days = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
  return days[this.getDay()];
}
  
export default {
  data(){
    return{
      loader:true,
      medewerkers: [
        { 'value': null,
          'text': 'Geen medewerker'
        }
      ],
      weeks: [
        { 'value': null,
          'text': 'Geen week'}
      ],
      years: [
        { 'value': null,
          'text': 'Selecteer Jaar'}
      ],
      fields: [
        { key: 'Date',
          label: 'Datum'},
        { key: 'Description',
          label: 'Omschrijving'},
        { key: 'AmountNeeded',
          label: 'Contracturen'},
        { key: 'AmountMade',
          label: 'Gewerkte uren'},
        { key: 'show_details',
          label: ''}
      ],
      subFields: [
        { key: 'WorkOrder',
          label: 'Werkbon'},
        { key: 'Description',
          label: 'Omschrijving'},
        { key: 'AmountMade',
          label: 'Uren'}
      ],
      hours: [],
      days: [],
      objDates: {},
      selectedMedewerker: null,
      selectedMedewerkerIndex: -1,
      selectedWeek: null,
      selectedYear: null,
    }
  },
  mounted() {

    this.$api.medewerkers.fetch().then((data) => {
      this._.forEach(data.results, medew => {
        // Only add medewerkers which have "Werklocatie (filled with GC_CODE of TARIEF)" to the list
        if (medew['WERKLOCATIE'] !== null && medew['WERKWEEK'] !== null) {
          medew['value'] = medew['GC_ID'];
          medew['text'] = medew['PERSOON_GC_ID']['VOORLETTERS'] + " " + medew['PERSOON_GC_ID']['GC_OMSCHRIJVING']
          this.medewerkers.push(medew);
        }
      })
      this.loader = false;
    });

    let startYear = 2022;
    let curDate = new Date()
    for (let y = startYear; y <= curDate.getFullYear(); y++) {
      this.years.push({
        'value': y,
        'text': y
      })
    }
    this.selectedYear = curDate.getFullYear()
  },
  watch: {
    selectedMedewerker(newMedew, oldMedew) {
      this.selectedMedewerkerIndex = this._.findIndex(this.medewerkers, {'GC_ID': newMedew});
      this.loadData();
    },
    selectedWeek(newWeek, oldWeek) {
      this.loadData();
    },
    selectedYear(newYear, oldYear) {
      this.getWeeks(newYear)
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      return item.Rowstyling;
    },
    getDateOfWeek(w, y) {
        var d = (1 + (w - 1) * 7) + 3; // 1st of January + 7 days for each week
        return new Date(y, 0, d);
    },

    getWeeks(year) {
      let today = new Date()

      this.weeks = [
        { 'value': null,
          'text': 'Geen week'}
      ]
      //this.loadData()
      //this.medewerkers = this.medewerkers.concat(this.$config.MEDEWERKERS_LIST);
      for (let w = 1; w <= 53; w++) {
        let dateRange = this.getDateRangeOfWeek(w, year);
        let line = {
          'value': w,
          'text': this._.padStart(w, 2, "0") + ' [' + dateRange[0].getString() + " - " + dateRange[(dateRange.length - 1)].getString() + ']'
        }

        // Add marker if it is the current week
        if (today > dateRange[0] && today < dateRange[(dateRange.length - 1)]) {
          line.text = "=> " + line.text
        }

        this.weeks.push(line);
      }
    },
    getDateRangeOfWeek(weekNo, y) {
      let d1, d2, dates, now, numOfdaysPastSinceLastMonday, rangeIsFrom, rangeIsTo, timesWeeks;
      d1 = new Date(''+y+'');
      now = new Date();
      dates = [];

      numOfdaysPastSinceLastMonday = d1.getDay() - 1;
      d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
    
      timesWeeks = (weekNo - d1.getWeek())
      if (d1.getFullYear() !== now.getFullYear()) {
        timesWeeks = weekNo
      }
      d1.setDate(d1.getDate() + (7 * timesWeeks));
      //console.log(d1)
      //rangeIsFrom = (d1.getMonth() + 1) + "-" + d1.getDate() + "-" + d1.getFullYear();
      //console.log(rangeIsFrom)
      dates.push(d1)

      for (let i = 1; i<=6; i++) {
        d2 = new Date(d1);
        d2.setDate(d1.getDate() + i);
        dates.push(d2)
      }      

      return dates;
    },

    loadData(config = {}) {
      // Are all fields selected
      if (this.selectedMedewerker === null 
          || this.selectedWeek === null) {
            return;
          }

      // Show loader
      this.loader = true
      this.days = [];

      // Get the dates of this week
      let week = this.getDateRangeOfWeek(this.selectedWeek, this.selectedYear);
      config['startdate'] = week[0];
      config['enddate'] = week[(week.length - 1)];

      // Create the dates object for storing the rows
      this.objDates = {}
      this._.forEach(week, d => {

        // Now get the medewerker contract for this date
        let medew = this.medewerkers[this.selectedMedewerkerIndex]
        let WerkWeek = medew['WERKWEEK']
        
        let WerkDag = this._.findIndex(WerkWeek['DAGEN'], {'WEEKDAG_NR': (d.getDay() + 1) + ""})
        let AmountNeeded = "";
        if (WerkDag >= 0) {
          AmountNeeded = WerkWeek['DAGEN'][WerkDag]['AANTAL_CONTRACTUREN']
        }
        // Create an object with the needed data
        this.objDates[d.getString()] = {
          'Date': d.getWeekDay() + " " + d.getString(),
          'Description': 'Totaal aantal uren',
          'AmountMade': 0,
          'AmountNeeded': AmountNeeded,
          'Rowstyling': '',
          'Items': [],
        };
      });

      //this.loader = false;
      //return;


      config['employee'] = this.selectedMedewerker;
      // Execcute fetch for data
      this.$api.uren.fetch(config).then((data) => {

        // Clear the current array
        this.days = [];

        // Create the array of objects for the (nested) table
        this._.forIn(data.results, (items, date) => {
          // Parse the date so we can format it
          let d = new Date(date);
          let formattedDate = this._.padStart(d.getDate(), 2, "0") + "-" +
                            this._.padStart((d.getMonth() + 1), 2, "0") + "-" +
                            d.getFullYear();
          
          // Process all the items for this data
          this._.forEach(data.results[date], item => {
            // Now process the item
            this.objDates[d.getString()]['Items'].push({
              'WorkOrder': {
                'Code': item['CODE'],
                'CustomerName': item['KLANT'],
                'Description': item['OMSCHRIJVING'],
              },
              'Description': item['TAAK'],
              'AmountMade': item['TIJD']
            });

            // Sum AMOUNT of hours for the view
            this.objDates[d.getString()]['AmountMade'] += item['TIJD'];
          });

        });

        // Push object to view array
        let totalHours = 0;
        this._.forEach(this.objDates, ob => {
          // Add all the hours to a var for the last row
          totalHours += ob['AmountMade'];

          // _showDetails if less then 8
          if (ob['AmountMade'] < ob['AmountNeeded']) {
            //ob['_showDetails'] = true;
            //ob['Rowstyling'] = 'table-danger';
          } else if (ob['AmountMade'] > ob['AmountNeeded']) {
            //ob['Rowstyling'] = 'table-warning'
          }
          // Add the item to the view array
          this.days.push(ob);
        });

        this.days.push({
          'Description': 'Totaal',
          'AmountMade': totalHours,
        });

        // Hide loader
        this.loader = false
      });
    }
  }
  
}
</script>