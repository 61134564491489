<template>
  <div id="app">
 <!-- Loader starts-->
    <div class="loader-wrapper"  :class="{'loderhide' : !show }">
      <div class="loader bg-white">
        <div class="whirly-loader"> </div>
      </div>
    </div>
  <!-- Loader ends-->

    <!--<Main/>-->
    <router-view></router-view>
  </div>
</template>

<script>
import store from './store/index'

export default {
  name: 'app',
  data(){
    return{
      show: true
    }
  },
  created() {
    window.addEventListener('beforeunload', function(event) {
      //console.log(this)
      //event.returnValue = 'Write something'
    }),

    store.dispatch('auth/getState').then(() => {
      //console.log("State loaded, isAuthenticated:", store.getters['auth/isAuthenticated'])
      if (store.getters['auth/isAuthenticated']) {
        //console.log(this.$route)
        this.$router.push('/')
      }
    })

  },
  mounted() {
      this.timeOut();
    },
    methods:{   
      timeOut(){
            var self = this;
            setTimeout(function(){
                self.show = false;
            }, 1000);
      }
     }
  }
</script>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
