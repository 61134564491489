<template>
    <div>
      <Breadcrumbs main="Relaties" title="Lijst"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                      <h5>Relatieoverzicht</h5><!--<span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span>-->
                    </div>
                    <div class="card-body">
                      <b-row  class="mb-3">
                          <b-col md="6">
                              <b-input-group class="datatable-btn">
                                  <b-form-input v-model="search" placeholder="Typ om te zoeken"></b-form-input>
                                  <b-input-group-append>
                                      <b-button :disabled="!search" @click="search=''">CLEAR</b-button>
                                  </b-input-group-append>
                              </b-input-group>
                          </b-col>
                          <b-col md="6">
                              <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                  <b-form-select v-model="pageSize" :options="pageOptions"></b-form-select>
                              </b-form-group>
                          </b-col>
                      </b-row>
                      <div class="table-responsive mb-0">
                            <b-table striped hover :busy="loader" :fields="fields" :items="relations" :tbody-tr-class="rowClass">
                              <template #table-busy>
                                <div class="text-center text-primary my-2">
                                  <b-spinner class="align-middle"></b-spinner>
                                  <strong>Laden...</strong>
                                </div>
                              </template>

                              <template v-slot:cell(actions)="{ item }">
                                <span><b-btn :to="'/relations/detail/' + item.GC_ID">Details</b-btn></span>
                              </template>


                            </b-table>
                      <!--</div>-->
                      <!--<b-overlay :show="show" rounded="sm">
                      <b-row  class="mb-3">
                          <b-col md="6">

                              <b-input-group class="datatable-btn">
                                  <b-form-input v-model="search" placeholder="Typ om te zoeken"></b-form-input>
                                  <b-input-group-append>
                                      <b-button :disabled="!search" @click="search=''">CLEAR</b-button>
                                  </b-input-group-append>
                              </b-input-group>

                          </b-col>

                          <b-col md="6">
                              <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                  <b-form-select v-model="pageSize" :options="pageOptions"></b-form-select>
                              </b-form-group>
                          </b-col>
                      </b-row>

                        <div class="table-responsive datatable-vue m-0 vue-smart">
                          <v-table :data="relations" class="table">
                          <thead slot="head">
                              <th>Naam</th>
                              <th>Adres</th>
                              <th>Telefoon</th>
                              <th>Email</th>
                              <th>Acties</th>
                          </thead>
                          <tbody slot="body" slot-scope="{displayData}">
                              <tr v-for="row in displayData" :key="row.GC_ID">
                                <td>{{ row.GC_OMSCHRIJVING }}</td>
                                <td>
                                  
                                  </td>
                                <td>
                                  <a href="tel:'`${row.TELEFOON1}`">{{ row.TELEFOON1 }}</a><br />
                                  <a href="tel:'`${row.TELEFOON2}`">{{ row.TELEFOON2 }}</a><br />
                                  <a href="tel:'`${row.MOBIEL}`">{{ row.MOBIEL   }}</a>
                                </td>
                                <td>
                                  {{ row.EMAIL }}
                                </td>
                                <td>
                                  <b-button id="default-sm-primary" variant="primary" size="sm" :to="'/relations/detail/' + row.GC_ID">Details</b-button>
                                </td>
                              </tr>
                          </tbody>
                        </v-table>-->
                      
                        <!--<b-pagination-nav
                                  :number-of-pages="totalPages"
                                  :no-page-detect=true
                                  v-model="currentPage"
                                  :link-gen="linkGen"
                                  
                                  base-url="/relations/list"
                                  align="right"
                                  use-router
                                  >
                          </b-pagination-nav>-->
                          <b-pagination
                            :v-model="currentPage"
                            :per-page="pageSize"
                            :total-rows="totalRows"
                            @change="pageNav"
                            >

                          </b-pagination>
							          </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import _ from 'lodash'

export default {
  data(){
    return{
      loader:true,
      fields: [
        {'key': 'GC_OMSCHRIJVING',
         'label': 'Naam'},
        {'key': 'ADRESSSS',
         'label': 'Adres'},
        {'key': 'TELEFOON1',
         'label': 'Telefoon'},
        {'key': 'EMAIL',
         'label': 'Email'},
        {'key': 'actions',
         'label': 'Acties'},
      ],
      relations: [],
      totalRows: 1,
      currentPage: 1,
      totalPages: 1,
      pageSize: 10,
      pageOptions: [5, 10, 15, 20, 25],
      search: "",
    }
  },

  mounted() {
    // Set the initial number of items
    //this.totalRows = this.items.length
    //console.log(this.params.data)
    //store.dispatch('relations/getRelations')
    //console.log(store.getters['relations/relations'])
    this.loadList({page: 1});
  },

  watch: {
    search: _.debounce(function(e) {
        this.loadList({search: this.search});
    }, 500)
  },
  methods: {
    pageNav(toPage) {
      if (toPage === null) return;
      this.loadList({page: toPage})
    },
    rowClass(item, type) {
      return '';
      //if (!item || type !== 'row') return;
      //return item.Rowstyling;
    },

    loadList(config = {}) {
      // Add some additional parameters to the get object
      config.page_size = this.pageSize

      // Show loader
      this.loader = true

      // Execcute fetch for data
      this.$api.relaties.fetch(config).then((data) => {
        this.relations = data.results
        this.currentPage = data.curent_page
        this.totalPages = data.total_pages
        this.totalRows = data.count

        // Hide loader
        this.loader = false
        console.log(data.results)
      });
    }
  }
}
</script>