// Based on https://itnext.io/vue-tricks-smart-api-module-for-vuejs-b0cae563e67b


import { RelatieApiService } from './relaties';
import { MedewerkerApiService } from './medewerkers';
import { UrenApiService } from './uren';
import { KeepingApiService } from './keeping';

export const $api = {
  relaties: new RelatieApiService(),
  medewerkers: new MedewerkerApiService(),
  uren: new UrenApiService(),
  keeping: new KeepingApiService(),
}