import Vue from 'vue'
import Router from "vue-router";
import store from '../store/'

import Body from '../components/body'
import Auth from '../auth/auth'

/* Auth */
import login from '../auth/login'


/* Hours */
import HoursWeekly from '../pages/hours/weekly'
import HoursKeeping from '../pages/hours/keeping'

/* Hours */
import RelationList from '../pages/relations/list'
import RelationDetail from '../pages/relations/detail'

/* Error Pages */
import Error400 from '../errors/error_400'
import Error401 from '../errors/error_401'
import Error403 from '../errors/error_403'
import Error404 from '../errors/error_404'
import Error500 from '../errors/error_500'
import Error503 from '../errors/error_503'

/* Dashboards */
import Dashboard from '../pages/dashboard/default'


/* User Profile */
import UserProfile from '../pages/users/profile'
import UserEditProfile from '../pages/users/edit-profile'
import UserCards from '../pages/users/cards'




// component

Vue.use(Router)

let AppTitle = "A. Geertsma Installatiebedrijf Portal"

const routes = [
  { path: '', redirect: { name: 'default' }},
  {
    path: '/dashboard',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'default',
        component: Dashboard,
        meta: {
          title: 'Dashboard',
        }
      },
    ]
  },
  {
    path: '/relations',
    component: Body,
    children: [
    {
      path: 'list',
      name: 'list',
      component: RelationList,
      meta: {
        title: 'Relaties',
      }
    },
    {
      path: 'detail/:id',
      name: 'detail',
      component: RelationDetail,
      meta: {
        title: 'Relatiedetails',
      }
    },
    ]
  },
  {
    path: '/hours',
    component: Body,
    children: [
    {
      path: 'weekly',
      name: 'weekly',
      component: HoursWeekly,
      meta: {
        title: 'Weekstaten',
      }
    },
    {
      path: 'keeping',
      name: 'keeping',
      component: HoursKeeping,
      meta: {
        title: 'Keeping',
      }
    },
    ]
  },
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: login,
        meta: {
          title: 'Login',
        }
      },
    ]
  },
/*{
  path: '/widgets',
  component: Body,
  children: [
  {
    path: 'general',
    name: 'general',
    component: GeneralWidgets,
    meta: {
      title: 'General Widgets',
    }
  },
  {
    path: 'chart',
    name: 'chart',
    component: ChartWidgets,
    meta: {
      title: 'Chart Widgets',
    }
  },
  ]
},
{
  path: '/base',
  component: Body,
  children: [
  {
    path: 'alert',
    name: 'alert',
    component: alert,
    meta: {
      title: 'Alert',
    }
  },
  {
    path: 'state-color',
    name: 'Statecolor',
    component: Statecolor,
    meta: {
      title: 'Statecolor',
    }
  },
  {
    path: 'helper-classes',
    name: 'Helperclasses',
    component: Helperclasses,
    meta: {
      title: 'Helperclasses',
    }
  },
  {
    path: 'avatars',
    name: 'Avatars',
    component: Avatars,
    meta: {
      title: 'Avatars',
    }
  },
  {
    path: 'grid',
    name: 'Grid',
    component: Grid,
    meta: {
      title: 'Grid',
    }
  },
  {
    path: 'tag-pills',
    name: 'Tagpills',
    component: Tagpills,
    meta: {
      title: 'Tagpills',
    }
  },
  {
    path: 'typography',
    name: 'Typography',
    component: Typography,
    meta: {
      title: 'Typography',
    }
  },
  {
    path: 'progress-bar',
    name: 'Progressbar',
    component: Progressbar,
    meta: {
      title: 'Progressbar',
    }
  },
  {
    path: 'modal',
    name: 'Modal',
    component: Modal,
    meta: {
      title: 'Modal',
    }
  },
  {
    path: 'popover',
    name: 'Popover',
    component: Popover,
    meta: {
      title: 'Popover',
    }
  },
  {
    path: 'tooltip',
    name: 'Tooltip',
    component: Tooltip,
    meta: {
      title: 'Tooltip',
    }
  },
  {
    path: 'loader',
    name: 'Spinners',
    component: Spinners,
    meta: {
      title: 'Spinners',
    }
  },
  {
    path: 'dropdown',
    name: 'Dropdown',
    component: Dropdown,
    meta: {
      title: 'Dropdown',
    }
  },
  {
    path: 'tab-bootstrap',
    name: 'BootstrapTabs',
    component: BootstrapTabs,
    meta: {
      title: 'BootstrapTabs',
    }
  },
  {
    path: 'tab-material',
    name: 'MaterialTabs',
    component: MaterialTabs,
    meta: {
      title: 'MaterialTabs',
    }
  },
  {
    path: 'accordion',
    name: 'Accordion',
    component: Accordion,
    meta: {
      title: 'Accordion',
    }
  },
  {
    path: 'box-shadow',
    name: 'Boxshadow',
    component: Boxshadow,
    meta: {
      title: 'Boxshadow',
    }
  },
  {
    path: 'lists',
    name: 'Lists',
    component: Lists,
    meta: {
      title: 'Lists',
    }
  },
  {
    path: 'navs',
    name: 'Navs',
    component: Navs,
    meta: {
      title: 'Navs',
    }
  }]
},
{
  path: '/advance',
  component: Body,
  children: [
  {
    path: 'scrollable',
    name: 'Scrollable',
    component: Scrollable,
    meta: {
      title: 'Scrollable',
    }
  },
  {
    path: 'sweetalert',
    name: 'Sweetalert',
    component: Sweetalert,
    meta: {
      title: 'Sweetalert',
    }
  },
  {
    path: 'tree',
    name: 'Tree',
    component: Tree,
    meta: {
      title: 'Tree',
    }
  },
  {
    path: 'rating',
    name: 'Rating',
    component: Rating,
    meta: {
      title: 'Rating',
    }
  },
  {
    path: 'pagination',
    name: 'Pagination',
    component: Pagination,
    meta: {
      title: 'Pagination',
    }
  },
  {
    path: 'ribbons',
    name: 'Ribbons',
    component: Ribbons,
    meta: {
      title: 'Ribbons',
    }
  },
  {
    path: 'tour',
    name: 'Tour',
    component: Tour,
    meta: {
      title: 'Tour',
    }
  },
  {
    path: 'range-slider',
    name: 'Rangeslider',
    component: Rangeslider,
    meta: {
      title: 'Rangeslider',
    }
  },
  {
    path: 'steps',
    name: 'Steps',
    component: Steps,
    meta: {
      title: 'Steps',
    }
  },
  {
    path: 'breadcrumb',
    name: 'Breadcrumb',
    component: Breadcrumb,
    meta: {
      title: 'Breadcrumb',
    }
  },
  {
    path: 'dropzone',
    name: 'Dropzone',
    component: Dropzone,
    meta: {
      title: 'Dropzone',
    }
  },
  {
    path: 'upload',
    name: 'Upload',
    component: Upload,
    meta: {
      title: 'Upload',
    }
  },
  {
    path: 'sticky',
    name: 'Sticky',
    component: Sticky,
    meta: {
      title: 'Sticky',
    }
  },
  {
    path: 'toastr',
    name: 'Toastr',
    component: Toastr,
    meta: {
      title: 'Toastr',
    }
  },
  {
    path: 'image-cropper',
    name: 'ImageCropper',
    component: ImageCropper,
    meta: {
      title: 'ImageCropper',
    }
  },
  {
    path: 'dragdrop',
    name: 'Draganddrop',
    component: Draganddrop,
    meta: {
      title: 'Draganddrop',
    }
  },
  ]
},
{
  path: '/chart',
  component: Body,
  children: [
  {
    path: 'google',
    name: 'Google Chart',
    component: GoogleChart,
    meta: {
      title: 'GoogleChart',
    }
  },
  {
    path: 'chartist',
    name: 'Chartist',
    component: Chartist,
    meta: {
      title: 'Chartist',
    }
  },
  ]
},
{
  path: '/gallery',
  component: Body,
  children:[
    {
      path: 'grid-gallery',
      name: 'GridGallery',
      component:GridGallery,
      meta: {
        title: 'GridGallery',
      }
    },
    {
      path: 'gallery-desc',
      name: 'GridGalleryDesc',
      component:GridGalleryDesc,
      meta: {
        title: 'GridGalleryDesc',
      }
    },
    {
      path: 'gallery-masonary',
      name: 'GalleryMasonary',
      component:GalleryMasonary,
      meta: {
        title: 'GalleryMasonary',
      }
    },
    {
      path: 'gallery-masonary-desc',
      name: 'GalleryMasonaryDesc',
      component:GalleryMasonaryDesc,
      
    },
    {
      path: 'hover-effect',
      name: 'HoverEffect',
      component:HoverEffect,
      meta: {
        title: 'HoverEffect',
      }
    } 
  ]
},
{
  path: '/editor',
  component: Body,
  children:[
    {
      path: 'simple-editor',
      name: 'SimpleEditor',
      component:SimpleEditor,
      meta: {
        title: 'SimpleEditor',
      }
    },
    {
      path: 'ck-editor',
      name: 'CkEditor',
      component:CkEditor,
       meta: {
        title: 'CkEditor',
      }
      
    }
  ]
},
{
  path:'/users',
  component: Body,
  children:[
    {
      path: 'profile',
      name: 'UserProfile',
      component:UserProfile,
       meta: {
        title: 'serProfile',
      }
    },
    {
      path: 'edit',
      name: 'UserEditProfile',
      component:UserEditProfile,
       meta: {
        title: 'serEditProfile',
      }
    },
    {
      path: 'cards',
      name: 'UserCards',
      component:UserCards,
       meta: {
        title: 'serCards',
      }
    }
  ]
},
{
  path:'/blog',
  component: Body,
  children:[
    {
      path: 'details',
      name: 'Blog',
      component:Blog,
       meta: {
        title: 'log',
      }
    },
    {
      path: 'single',
      name: 'BlogSingle',
      component:BlogSingle,
       meta: {
        title: 'logSingle',
      }
    }
  ]
},
{
  path:'/maps',
  component: Body,
  children:[
    {
      path: 'vue-google-maps',
      name: 'GoogleMaps',
      component:GoogleMaps,
       meta: {
        title: 'oogleMaps',
      }
    },
    {
      path: 'vue-leaflet-maps',
      name: 'VueLeaflet',
      component:VueLeaflet,
       meta: {
        title: 'ueLeaflet',
      }
    }
  ]
},
{
  path: '/icons',
  component: Body,
  children:[
  {
    path: 'flag',
    name: 'Flag',
    component: Flag,
     meta: {
        title: 'Flag',
      }
  },
  {
    path: 'ico',
    name: 'Ico',
    component: Ico,
     meta: {
        title: 'Ico',
      }
  },
  {
    path: 'themify',
    name: 'Themify',
    component: Themify,
     meta: {
        title: 'Themify',
      }
  },
  {
    path: 'fontawesome',
    name: 'Fontawesome',
    component: Fontawesome,
     meta: {
        title: 'Fontawesome',
      }
  },
  {
    path: 'feather_icon',
    name: 'FeatherIcon',
    component: FeatherIcon,
     meta: {
        title: 'FeatherIcon',
      }
  },
  {
    path: 'whether',
    name: 'WhetherIcon',
    component: WhetherIcon,
     meta: {
        title: 'WhetherIcon',
      }
  }
  ]
},
{
  path: '/form',
  component: Body,
  children: [
  {
    path: 'wizard',
    name: 'FormWizard',
    component: FormWizrd,
     meta: {
        title: 'FormWizrd',
      }
  },
  {
    path: 'validation',
    name: 'FormValidation',
    component: FormValidation,
     meta: {
        title: 'FormValidation',
      }
  },
  {
    path: 'inputs',
    name: 'FormBaseInputs',
    component: FormBaseInputs,
     meta: {
        title: 'FormBaseInputs',
      }
  },
  {
    path: 'checkbox-radio',
    name: 'FormCheckboxRadio',
    component: FormCheckboxRadio,
     meta: {
        title: 'FormCheckboxRadio',
      }
  },
  {
    path: 'input-groups',
    name: 'FormInputGroups',
    component: FormInputGroups,
     meta: {
        title: 'FormInputGroups',
      }
  },
  {
    path: 'mega-options',
    name: 'FormMegaOptions',
    component: FormMegaOptions,
     meta: {
        title: 'FormMegaOptions',
      }
  },
  {
    path: 'datepicker',
    name: 'FormDatepicker',
    component: FormDatepicker,
     meta: {
        title: 'FormDatepicker',
      }
  },
  {
    path: 'select2',
    name: 'FormSelect2',
    component: FormSelect2,
     meta: {
        title: 'FormSelect2',
      }
  },
  {
    path: 'switch',
    name: 'FormSwitch',
    component: FormSwitch,
     meta: {
        title: 'FormSwitch',
      }
  },
  {
    path: 'typeahead',
    name: 'FormTypeahead',
    component: FormTypeahead,
     meta: {
        title: 'FormTypeahead',
      }
  },
  {
    path: 'clipboard',
    name: 'FormClipboard',
    component: FormClipboard,
     meta: {
        title: 'FormClipboard',
      }
  },
  {
    path: 'touchspin',
    name: 'FormTouchspin',
    component: FormTouchspin,
     meta: {
        title: 'FormTouchspin',
      }
  },
  {
    path: 'mega-options',
    name: 'Form Mega Options',
    component: FormMegaOptions,
     meta: {
        title: 'FormMegaOptions',
      }
  }
  ]
},
{
  path: '/table',
  component: Body,
  children: [
  {
    path: 'basic',
    name: 'BootstrapBasic',
    component: BootstrapBasic,
     meta: {
        title: 'BootstrapBasic',
      }
  },
  {
    path: 'sizing',
    name: 'BootstrapSizing',
    component: BootstrapSizing,
     meta: {
        title: 'BootstrapSizing',
      }
  },
  {
    path: 'border',
    name: 'BootstrapBorder',
    component: BootstrapBorder,
     meta: {
        title: 'BootstrapBorder',
      }
  },
  {
    path: 'styling',
    name: 'BootstrapStyling',
    component: BootstrapStyling,
     meta: {
        title: 'BootstrapStyling',
      }
  },
  {
    path: '/datatable-basic',
    name: 'DatatableBasic',
    component: DatatableBasic,
     meta: {
        title: 'DatatableBasic',
      }
  },
  {
    path: '/datatable-select-delete',
    name: 'DatatableSelectDelete',
    component: DatatableSelectDelete,
     meta: {
        title: 'DatatableSelectDelete',
      }
  },
  {
    path: '/filter-table',
    name: 'FilterTable',
    component: FilterTable,
     meta: {
        title: 'FilterTable',
      }
  },
  {
    path: '/datatable-export',
    name: 'DatatableExport',
    component: DatatableExport,
     meta: {
        title: 'DatatableExport',
      }
  },
  {
    path: 'smarttable',
    name: 'SmartTable',
    component: SmartTable,
     meta: {
        title: 'SmartTable',
      }
  },
  ]
},
{
  path: '/buttons',
  component: Body,
  children:[
  {
    path: 'default',
    name: 'default_buttons',
    component: Default_buttons,
     meta: {
        title: 'Default_buttons',
      }
  },
  {
    path: 'flat',
    name: 'flat_buttons',
    component: Flat_buttons,
     meta: {
        title: 'Flat_buttons',
      }
  },
  {
    path: 'edge',
    name: 'edge_buttons',
    component: Edge_buttons,
     meta: {
        title: 'Edge_buttons',
      }
  },
  {
    path: 'raised',
    name: 'raised_buttons',
    component: Raised_buttons,
     meta: {
        title: 'Raised_buttons',
      }
  },
  {
    path: 'group',
    name: 'group_buttons',
    component: Group_buttons,
     meta: {
        title: 'Group_buttons',
      }
  }
  ]
},
{
  path: '/ecommerce',
  component: Body,
  children: [

  {
    path: 'product',
    name: 'Product',
    component: Products,
     meta: {
        title: 'Products',
      }
  },
  {
    path: 'details/:id',
    name: 'Product Details',
    component: ProductDetails,
     meta: {
        title: 'ProductDetails',
      }
  },
  {
    path: 'cart',
    name: 'Cart',
    component: Cart,
     meta: {
        title: 'Cart',
      }
  },
  {
    path: 'wishlist',
    name: 'Wishlist',
    component: Wishlist,
     meta: {
        title: 'Wishlist',
      }
  },
  {
    path: 'checkout',
    name: 'Checkout',
    component: Checkout,
     meta: {
        title: 'Checkout',
      }
  },
  {
    path: 'invoice',
    name: 'Invoice',
    component: Invoice,
     meta: {
        title: 'Invoice',
      }
  },
  {
    path: 'success',
    name: 'Success',
    component: Success,
     meta: {
        title: 'Success',
      }
  },
  {
    path: 'order/history',
    name: 'Order History',
    component: OrderHistory,
     meta: {
        title: 'OrderHistory',
      }
  },
  {
    path: 'payment/details',
    name: 'payment Details',
    component: PaymentDetails,
     meta: {
        title: 'PaymentDetails',
      }
  },
  ]
},
{
  path:'/cards',
  component:Body,
  children:[
  {
    path:'basic',
    name:'Basic',
    component:Basic,
    meta: {
      title: 'BootstrapStyling',
    }
  },
  {
    path:'creative',
    name:'Creative',
    component:Creative,
    meta: {
      title: 'Creative',
    }
  },
  {
    path:'tabbed',
    name:'Tabbed',
    component:Tabbed,
    meta: {
      title: 'Tabbed',
    }
  },
  {
    path:'draggable',
    name:'Draggable',
    component:Draggable,
    meta: {
      title: 'Draggable',
    }
  }
  ]
},
{
  path: '/app',
  component: Body,
  children: [
  {
    path: 'todo',
    name: 'todo',
    component: Todo,
     meta: {
        title: 'Todo',
      }
  },
  {
    path: 'firebase-todo',
    name: 'FirebaseTodo',
    component: FirebaseTodo,
     meta: {
        title: 'FirebaseTodo',
      }
  },
  {
    path: 'chat',
    name: 'chat',
    component: Chat,
     meta: {
        title: 'Chat',
      }
  },
  {
    path: 'email',
    name: 'Email',
    component: Email,
     meta: {
        title: 'Email,',
      }
  },
  {
    path:'calendar',
    name:'Calendar',
    component:Calendar,
    meta: {
      title: 'Calendar',
    }
  }
  ]
},
{
  path: '/timeline',
  component: Body,
  children: [
  {
    path: 'one',
    name: 'TimelineOne',
    component: TimelineOne,
     meta: {
        title: 'TimelineOne',
      }
  },
  {
    path: 'two',
    name: 'TimelineTwo',
    component: TimelineTwo,
    meta: {
      title: ' TimelineTwo',
    }
  }
  ]
},
{
  path: '/auth',
  component: Auth,
  children: [
  {
    path: 'login',
    name: 'Login 1',
    component: login,
    meta: {
      title: 'Login',
    }
  },
  {
    path: 'register',
    name: 'register 1',
    component: Register,
    meta: {
      title: ' Register',
    }
  }
  ]
},
{
  path: '/pages',
  component: Body,
  children:[
    {
      path:'/pages/sample-page',
      name:'SamplePage',
      component:SamplePage,
      meta: {
      title: 'SamplePage',
    }
    },
    {
      path:'/pages/pricing',
      name:'Pricing',
      component:Pricing,
      meta: {
      title: 'Pricing',
    }
    }
  ]
},
{
  path: '/pages',
  component: Body,
  children:[
    {
      path:'/pages/faq',
      name:'Faq',
      component:Faq,
      meta: {
      title: 'Faq',
    }
    },
    {
      path:'/pages/support',
      name:'Support',
      component:Support,
      meta: {
      title: 'Support',
    }
    },
    {
      path:'/pages/social-app',
      name:'Social',
      component:Social,
      meta: {
      title: 'Social',
    }
    }
  ]
},
{
  path:'/job',
  component:Body,
  children:[
    {
      path:'/job/list',
      name:'JobList',
      component:JobList,
      meta: {
      title: 'JobList',
    }
    },
    {
      path:'/job/details/:id',
      name:'JobDetails',
      component:JobDetails,
      meta: {
      title: 'JobDetails,',
    },
      props:true
    },
    {
      path:'/job/apply/:id',
      name:'JobApply',
      component:JobApply,
      meta: {
      title: 'JobApply,',
    },
      props:true
    }
  ]
},
{
  path:'/search',
  component:Body,
  children:[
    {
      path:'/pages/search',
      name:'Search',
      component:Search,
      meta: {
      title: 'Search',
    }
    }
  ]
},
{
  path:'/learning',
  component:Body,
  children:[
    {
      path:'/learning/list',
      name:'Learninglist',
      component:Learninglist,
      meta: {
      title: 'Learninglist',
    }
    },
    {
      path:'/learning/details/:id',
      name:'Coursedetailed',
      component:Coursedetailed,
      meta: {
      title: 'Coursedetailed,',
      },
      props:true
    }
  ]
},
{
  path:'/knowledgebase',
  component:Body,
  children:[
    {
      path:'/',
      name:'Knowledgebase',
      component:Knowledgebase,
      meta: {
      title: 'Knowledgebase',
    }
    }
  ]
},
{
  path:'/error-400',
  name:'Error400',
  component:Error400,
  meta: {
      title: 'Error400',
    }
},
{
  path:'/error-401',
  name:'Error401',
  component:Error401,
  meta: {
      title: 'Error401',
    }
},
{
  path:'/error-403',
  name:'Error403',
  component:Error403,
  meta: {
      title: 'Error403',
    }
},
{
  path:'/error-404',
  name:'Error404',
  component:Error404,
  meta: {
      title: 'Error404',
    }
},
{
  path:'/error-500',
  name:'Error500',
  component:Error500,
  meta: {
      title: 'Error500',
    }
},
{
  path:'/error-503',
  name:'Error503',
  component:Error503,
  meta: {
      title: 'Error503',
    }
},
{
  path:'/maintenance',
  name:'Maintenance',
  component:Maintenance,
  meta: {
      title: 'Maintenance',
    }
},
{
  path:'/comingsoon/comingsoon-simple',
  name:'ComingsoonSimple',
  component:ComingsoonSimple,
  meta: {
      title: 'ComingsoonSimple',
    }
},
{
  path:'/comingsoon/comingsoon-image',
  name:'ComingsoonImage',
  component:ComingsoonImage,
  meta: {
      title: 'ComingsoonImage',
    }
},
{
  path:'/comingsoon/comingsoon-video',
  name:'ComingsoonVideo',
  component:ComingsoonVideo,
  meta: {
      title: 'ComingsoonVideo',
    }
},
{
  path:'/authentication/login/image',
  name:'LoginImage',
  component:LoginImage,
  meta: {
      title: 'LoginImage',
    }
},
{
  path:'/authentication/login/video',
  name:'LoginVideo',
  component:LoginVideo,
  meta: {
      title: 'LoginVideo',
    }
},
{
  path:'/authentication/register/image',
  name:'RegisterImage',
  component:RegisterImage,
  meta: {
      title: 'RegisterImage',
    }
},
{
  path:'/authentication/register/video',
  name:'RegisterVideo',
  component:RegisterVideo,
  meta: {
      title: 'RegisterVideo',
    }
},
{
  path:'/authentication/unlockuser',
  name:'Unlock',
  component:Unlock,
  meta: {
      title: 'Unlock',
    }
},
{
  path:'/authentication/forgetpassword',
  name:'ForgetPassword',
  component:ForgetPassword,
  meta: {
      title: 'ForgetPassword',
    }
},
{
  path:'/authentication/resetpassword',
  name:'ResetPassword',
  component:ResetPassword,
  meta: {
      title: 'ResetPassword',
    }
},*/

];

const router = new Router({
  routes,
  //base: '/endless/',
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  //console.log("beforeEach", store.getters)
  //console.log("To", to)
  // Set page title
  if(to.meta.title)
    document.title = to.meta.title + " | " + AppTitle;

  // Paths to exclude from logged ini
  const  path = ['/auth/login','/auth/register'];

  if (!store.getters['auth/isAuthenticated'] && to.name !== 'Login') {
    //console.log("beforeEach: not Authenticated")  
    // Not authenticated, go to login
    next({ name: 'Login' });
    //return;
  } else {
    // Authenticated, navigate
    //console.log("beforeEach: navigate")
    next();
    //return;
  }
  return;
});

export default router
