<template>
    <div>
        <Breadcrumbs main="Uren" title="Keeping"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                  <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                        <h5 slot="header">Keeping</h5>
                        <b-card-text class="mb-0">
                          <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="c_form_last_name">Week</label>
                                    <b-form-select type="text" id="c_form_first_name" :disabled="loader" :options="this.weeks" v-model="selectedWeek"></b-form-select>
                                </div>
                                <div class="col-md-6 mb-3">
                                  <label for="default-info"></label>
                                  <b-button id="default-info" variant="info" :disabled="loader" v-on:click="loadData()">Vernieuw</b-button>
							                    <b-tooltip target="default-info" triggers="hover" placement="top">Herlaad de huidige selectie</b-tooltip>
                                </div>
                                <!--<div class="col-md-4 mb-3">
                                    <label for="c_form_username">Username</label>
                                    <b-form-input type="text" id="c_form_username" v-model="c_form.username" :state="c_form_result.username" placeholder="Username"></b-form-input>
                               </div>-->
                          </div>
                          <div class="table-responsive mb-0">
                            <b-table striped hover :busy="loader" :fields="fields" :items="days" :tbody-tr-class="rowClass">
                              <template #table-busy>
                                <div class="text-center text-primary my-2">
                                  <b-spinner class="align-middle"></b-spinner>
                                  <strong>Laden...</strong>
                                </div>
                              </template>

                              <template #cell(show_details)="row">
                                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                                  {{ row.detailsShowing ? 'Verberg' : 'Toon' }} details</b-button>
                              </template>

                              <template #row-details="row">
                                <b-table :fields="subFields" :items="row.item.Items">
                                  <template #cell(WorkOrder)="data">
                                    <span v-html="data.value.Description"></span>
                                  </template>
                                </b-table>
                              </template>
                            </b-table>
                          </div>
                          <!--<div class="table-responsive datatable-vue m-0 vue-smart">
                            <v-table :data="hours" class="table">
                              <thead slot="head">
                                  <th>Datum</th>
                                  <th>Omschrijving</th>
                                  <th>Tijd</th>
                              </thead>
                              <tbody slot="body" slot-scope="{displayData}">
                                  <tr v-for="row in displayData" :key="row.GC_ID">
                                    <td>{{ row.DATUM }}</td>
                                    <td>{{ row.OMSCHRIJVING }}</td>
                                    <td>{{ row.AMOUNT }}</td>
                                  </tr>
                              </tbody>
                            </v-table>
                            </div>-->
                        </b-card-text>
                    </b-card>
              </div>               
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
const axios = require('axios').default

// Returns the ISO week of the date.
Date.prototype.getWeek = function() {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}
Date.prototype.getString = function() {
  return ("00" + this.getDate()).substr(-2) + "-" + 
         ("00" + this.getMonth()).substr(-2) + "-" +
         this.getFullYear();
}
Date.prototype.getWeekDay = function() {
  let days = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
  return days[this.getDay()];
}
  
export default {
  data(){
    return{
      loader:true,
      medewerkers: [
        { 'value': null,
          'text': 'Geen medewerker'
        }
      ],
      weeks: [
        { 'value': null,
          'text': 'Geen week'}
      ],
      fields: [
        { key: 'Date',
          label: 'Datum'},
        { key: 'Description',
          label: 'Omschrijving'},
        { key: 'AmountNeeded',
          label: 'Contracturen'},
        { key: 'AmountMade',
          label: 'Gewerkte uren'},
        { key: 'show_details',
          label: ''},
      ],
      subFields: [
        { key: 'Description',
          label: 'Omschrijving'},
        { key: 'AmountMade',
          label: 'Uren'},
        { key: 'State', 
          label: 'Status'},
      ],
      hours: [],
      days: [],
      objDates: {},
      selectedMedewerker: null,
      selectedMedewerkerIndex: -1,
      selectedWeek: null,
    }
  },
  mounted() {
    this.$api.keeping.fetch({'uri': 'weeks'}).then(data => {
      console.log(data)
      this._.forEach(data, w => {
        //console.log(w)
        let line = {
          'value': w['id'],
          'text': w['description'] + " [ " + w['hours'] + " ]"
        }
        this.weeks.push(line);
      });
      this.loader = false;
    });
  },
  watch: {
    selectedWeek(newWeek, oldWeek) {
      this.loadData();
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      return item.Rowstyling;
    },
    loadData(config = {}) {
      // Are all fields selected
      this.loader = true
      if (this.selectedWeek === null) {
            return;
          }

      //console.log(this.selectedWeek)

      this.$api.keeping.fetch({'uri': 'weeks', 'week': this.selectedWeek}).then(data => {
        this.objDates = {};

        this._.forEach(data, item => {
          if (!this.objDates.hasOwnProperty(item['date'])) {
            console.log("Create date")
            this.objDates[item['date']] = {
              'Date': item['date'],
              'Description': 'Totaal aantal uren',
              'AmountMade': 0,
              'AmountNeeded': 8,
              'Rowstyling': '',
              'Items': [],
            }
          }

          this.objDates[item['date']]['Items']
          // Now process the item
          console.log(item)
          let Status = "Onverwerkt"
          if (item['task_id'] == 210917) {
            Status = "Verwerkt"
          }

          this.objDates[item['date']]['Items'].push({
            'WorkOrder': {
              'Code': '00000000',
              'CustomerName': '',
              'Description': '',
            },
            'Description': item['note'].replace(/\n/g, "<br />"),
            'AmountMade': item['hours'],
            'State': Status
          });

          // Sum AMOUNT of hours for the view
          this.objDates[item['date']]['AmountMade'] += item['hours'];
        })

        // Push object to view array
        this._.forEach(this.objDates, ob => {
          // _showDetails if less then 8
          if (ob['AmountMade'] < ob['AmountNeeded']) {
            ob['_showDetails'] = true;
            ob['Rowstyling'] = 'table-danger';
          } else if (ob['AmountMade'] > ob['AmountNeeded']) {
            ob['Rowstyling'] = 'table-warning'
          }
          // Add the item to the view array
          this.days.push(ob);
        });

        //console.log(data);
        this.loader = false;
      });
      return

      // Show loader
      this.loader = true
      // Get the dates of this week
      let week = this.getDateRangeOfWeek(this.selectedWeek, 2022);
      config['startdate'] = week[0];
      config['enddate'] = week[(week.length - 1)];

      // Create the dates object for storing the rows
      this.objDates = {}
      this._.forEach(week, d => {
        // Create a formatted date
        let formattedDate = this._.padStart(d.getDate(), 2, "0") + "-" +
                            this._.padStart((d.getMonth() + 1), 2, "0") + "-" +
                            d.getFullYear();

        // Now get the medewerker contract for this date
        let medew = this.medewerkers[this.selectedMedewerkerIndex]
        let WerkWeek = medew['WERKWEEK']
        
        let WerkDag = this._.findIndex(WerkWeek['DAGEN'], {'WEEKDAG_NR': (d.getDay() + 1) + ""})
        let AmountNeeded = 0;
        if (WerkDag >= 0) {
          AmountNeeded = WerkWeek['DAGEN'][WerkDag]['TIJDSDUUR']
        }
        // Create an object with the needed data
        this.objDates[d.getString()] = {
          'Date': d.getWeekDay() + " " + d.getString(),
          'Description': 'Totaal aantal uren',
          'AmountMade': 0,
          'AmountNeeded': AmountNeeded,
          'Rowstyling': '',
          'Items': [],
        };
      });

      // Push object to view array
      this._.forEach(this.objDates, ob => {
          // _showDetails if less then 8
          if (ob['AmountMade'] < ob['AmountNeeded']) {
            ob['_showDetails'] = true;
            ob['Rowstyling'] = 'table-danger';
          } else if (ob['AmountMade'] > ob['AmountNeeded']) {
            ob['Rowstyling'] = 'table-warning'
          }
          // Add the item to the view array
          this.days.push(ob);
        });

      //this.loader = false;
      //return;


      config['employee'] = this.selectedMedewerker;
      // Execcute fetch for data
      this.$api.uren.fetch(config).then((data) => {
        // Clear the current array
        this.days = [];

        // Create the array of objects for the (nested) table
        this._.forEach(data.results, item => {
          console.log(item);
          // Parse the date so we can format it
          let d = new Date(item['DATUM']);
          let formattedDate = this._.padStart(d.getDate(), 2, "0") + "-" +
                            this._.padStart((d.getMonth() + 1), 2, "0") + "-" +
                            d.getFullYear();

          // Now process the item
          this.objDates[d.getString()]['Items'].push({
            'WorkOrder': {
              'Code': item['WERKBON']['GC_CODE'],
              'CustomerName': item['WERKBON_GC_ID']['RELATIENAAM'],
              'Description': item['WERKBON']['GC_OMSCHRIJVING'],
            },
            'Description': item['GC_OMSCHRIJVING'],
            'AmountMade': item['AANTAL']
          });

          // Sum AMOUNT of hours for the view
          this.objDates[d.getString()]['AmountMade'] += item['AANTAL'];

        });

        // Sort the list by Date
        //let ordered = Object.keys(this.objDates).sort().reduce(
        //  (obj, key) => {
        //    obj[key] = this.objDates[key];
        //    return obj;
        //  }, 
        //  {}
        //);

        // Push object to view array
        this._.forEach(this.objDates, ob => {
          // _showDetails if less then 8
          if (ob['AmountMade'] < ob['AmountNeeded']) {
            ob['_showDetails'] = true;
            ob['Rowstyling'] = 'table-danger';
          } else if (ob['AmountMade'] > ob['AmountNeeded']) {
            ob['Rowstyling'] = 'table-warning'
          }
          // Add the item to the view array
          this.days.push(ob);
        });

        // Hide loader
        this.loader = false
      });
    }
  }
  
}
</script>