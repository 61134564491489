import axios from 'axios';

const state = {
    relations: [],
    relationsCount: 0,
};
const getters = {
    relations: state => state.relations,
    relationsCount: state => state.relationsCount,
};
const actions = {
    getRelations({ dispatch, commit, state, rootGetters}) {
        axios({
            url: 'relatie/',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + rootGetters['auth/token']
            },
            method: 'GET',
            responseType: 'json'
        })
        .then(function (response) {
            // Login OK!
            //console.log(response)
            commit('setRelations', response.data);
        })
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                //console.log(error.response.data);
                //console.log(error.response.status);
                //console.log(error.response.headers);
                commit('setError', 'Error while trying to login!');
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
                commit('setError', 'Error while trying to make the request!');
            } else {
                // Something happened in setting up the request that triggered an Error
                commit('setError', error.message);
            }
            //console.log(error.config);
            //commit('setError', 'Error while making a request  ');
        })
    },
};
const mutations = {
    setRelations(state, data) {
        //console.log(data)
        state.relations = data.results
        state.relationsCount = data.count
        //console.log(state)
    },
    setError(state, err) {
        state.error = err
    },
};
export default {
    namespaced: true,
  state,
  getters,
  actions,
  mutations
};