import axios from 'axios';
import SecureLS from 'secure-ls';

var ls = new SecureLS({encodingType: ''});

const state = {
    user: null,
    token: null,
    expiry: null,
    error: null,
};
const getters = {
    isAuthenticated: state => {
        return !!state.token
    },
    User: state => state.user,
    error: state => state.error,
    token: state => state.token, 
};
const actions = {
    getState({ dispatch, commit, state}) {
        //this.$api.token = "token";
        return new Promise(resolve => {
            let sess = ls.get('portal_session')
            if (typeof sess === 'object' && sess !== null) {
                // Session data exists in local storage
                // Now verify if the token is still valid
                let currDate = new Date().getTime()
                let exxDate = new Date(sess.expiry).getTime()
                

                // Token is not valid anymore, logout...
                if  (currDate >= exxDate) {
                    dispatch('LogOut')
                } else {
                    // Token is still valid
                    commit('setToken', {token: sess.token, expiry: sess.expiry})
                    commit('setError', null);
                }
                resolve();
            }
        })
    },
    LogIn({commit}, User) {
        return new Promise(resolve => {
            User.client = "Postman"
            axios({
                url: 'auth/login/',
                headers: {
                    'Content-Type': 'application/json',
                    //'Authorization': sessionStorage.getItem('token')
                },
                method: 'POST',
                data: User,
                responseType: 'json'
            })
            .then(function (response) {
                // Login OK!
                //console.log(response)
                axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.token
                commit('setToken', {token: response.data.token, expiry: response.data.expiry})
                commit('setUser', response.data.user)
                commit('setError', null);
                resolve();
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    //console.log(error.response.data);
                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                    commit('setError', 'Error while trying to login!');
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    commit('setError', 'Error while trying to make the request!');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    commit('setError', error.message);
                }
                //console.log(error.config);
                //commit('setError', 'Error while making a request  ');
            })
        })
    },
    LogOut({commit}){
        return new Promise(resolve => {
            //console.log(this.getters['auth/token'])
            axios({
                url: 'auth/logout/',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.getters['auth/token']
                },
                method: 'POST',
                responseType: 'json'
            })
            .then(function (response) {
                /*// Login OK!
                //console.log(response)
                commit('setToken', {token: response.data.token, expiry: response.data.expiry})
                commit('setError', null);
                resolve();*/

                let user = null
                commit('LogOut', user)
                resolve();
            })
            .catch(function (error) {
                let user = null
                commit('LogOut', user)
                resolve();

                /*if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    //console.log(error.response.data);
                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //
                }
                //console.log(error.config);
                //commit('setError', 'Error while making a request  ');*/
            })
        })
    }
};
const mutations = {
    setToken(state, data) {
        state.token = data.token
        state.expiry = new Date(data.expiry)
        ls.set('portal_session', state)
    },
    setUser(state, user){
        state.user = user
        state.user.full_name =  user.first_name + ' ' + user.last_name
        //console.log(state.user)
    },
    setError(state, err) {
        state.error = err
    },
    LogOut(state){
        state.token = null
        state.expiry = null
        state.user = null
        ls.remove('portal_session')
    },
};
export default {
    namespaced: true,
  state,
  getters,
  actions,
  mutations
};