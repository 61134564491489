import Vue from "vue";
import Vuex from "vuex";
import 'es6-promise/auto'
import auth from './modules/auth'
import layout from './modules/layout'
import menu from './modules/menu'

import storePlugins from "@/plugins/storePlugins";

// Portal modules
import relations from './modules/relations'

Vue.use(Vuex);

//const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
//export const store = new Vuex.Store({
    plugins: [storePlugins],
    modules: {
        layout,
        menu,
        auth,
        relations,
    },
    strict: false
});