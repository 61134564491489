import axios from "axios";
import Store from "@/store/"
import { reject } from "lodash";

/* BASE API SERVICE */
class BaseApiService {
    // Set Axios defaults
    baseURL = "http://127.0.0.1:8000/api/";
    resource;
  
    constructor(resource) {
        if (!resource) throw new Error("Resource is not provided");
        this.resource = resource;

        console.log(location.protocol)

        this.baseURL = location.protocol + "//" + location.host + "/api/"
        axios.defaults.baseURL = this.baseURL
        //console.log(location.host)
    }

    set token(token = "") {
        if (token == "") {
            axios.defaults.headers['Authorization'] = "";
        } else {
            axios.defaults.headers['Authorization'] = "Bearer " + token;
        }   
    }
    getUrl(id = "") {
        let uri = this.resource + '/';

        if (id !== "") {
          uri += id + "/";
        }
        return uri;
    }

    getHeaders(head = {}) {
        return {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + Store.getters['auth/token']
        }
    }
  
    handleErrors(err) {
        // Note: here you may want to add your errors handling
        console.log({ message: "Errors is handled here", err });
        console.log(err.response)
        return {
          'type': 'danger',
          'msg': 'Something went wrong' //err.response.
        }
    }
}

/* READ ONLY API SERVICE */
export class ReadOnlyApiService extends BaseApiService {
    constructor(resource) {
      super(resource);
    }
    async fetch(params = {}) {
        // Custom URI
        let URL = this.getUrl();
        if (params.hasOwnProperty('uri')) {
          URL = this.resource + "/" + params['uri']
          delete params.uri
        }

        return new Promise((resolve, reject) => {
            axios({
                url: URL,
                headers: this.getHeaders(),
                method: 'GET',
                responseType: 'json',
                params: params,
            })
            .then(function (response) {
                // Data received
                resolve(response.data);
            })  
            .catch((error) => {
                //console.log(this)
                reject(this.handleErrors(error));
                //return;
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error);
                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                    //commit('setError', 'Error while trying to login!');
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error);
                    //commit('setError', 'Error while trying to make the request!');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    commit('setError', error.message);
                    console.log(error)
                }
                //console.log(error.config);
                //commit('setError', 'Error while making a request  ');
            })
        })
    }
    async get(id, params = {}) {
      if (!id) throw Error("Id is not provided");

      return new Promise(resolve => {
        axios({
            url: this.getUrl(id),
            headers: this.getHeaders(),
            method: 'GET',
            responseType: 'json',
            params: params,
        })
        .then(function (response) {
            // Data received
            //console.log(response.data)
            resolve(response.data);
        })  
        .catch((error) => {
            //console.log(this)
            this.handleErrors(error);
            //return;
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error);
                //console.log(error.response.status);
                //console.log(error.response.headers);
                //commit('setError', 'Error while trying to login!');
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error);
                //commit('setError', 'Error while trying to make the request!');
            } else {
                // Something happened in setting up the request that triggered an Error
                commit('setError', error.message);
                console.log(error)
            }
            //console.log(error.config);
            //commit('setError', 'Error while making a request  ');
        })
    })
  }
}

/* FOR POST/PUT/DELETE SERVICE */   
export class ModelApiService extends ReadOnlyApiService {
    constructor(resource) {
      super(resource);
    }
    async post(data = {}) {
      try {
        const response = await fetch(this.getUrl(), {
          method: "POST",
          body: JSON.stringify(data)
        });
        const { id } = response.json();
        return id;
      } catch (err) {
        this.handleErrors(err);
      }
    }
    async put(id, data = {}) {
      if (!id) throw Error("Id is not provided");
      try {
        const response = await fetch(this.getUrl(id), {
          method: "PUT",
          body: JSON.stringify(data)
        });
        const { id: responseId } = response.json();
        return responseId;
      } catch (err) {
        this.handleErrors(err);
      }
    }
    async delete(id) {
      if (!id) throw Error("Id is not provided");
      try {
        await fetch(this.getUrl(id), {
          method: "DELETE"
        });
        return true;
      } catch (err) {
        this.handleErrors(err);
      }
    }
}